import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { v1 as uuidv1 } from 'uuid';
import prompts from "./prompts/prompts.json";
import { shuffle } from "./helper/helper";
import './App.css';

// TODO:
// animate card rotation -- done
// set name for prompts to random player
// more prompts
// Cancel screen
// Done screen

const animationLength = 0.25;
const millisToSec = 1000;
const replaceString = "{}";


type Player = {
  id: string;
  name: string;
}

enum GAME_STATE {
  INIT,
  IN_PROGRESS,
  DONE,
  CANCEL
}

const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const createNewPlayer = (name: string): Player => {
  const id = uuidv1();
  return { id, name };
}

const getRandomPlayers = (numPlayers: number, allPlayers: Player[]): Player[] => {
  if (!numPlayers) {
    return [];
  }
  let playersCopy = allPlayers.slice(0);
  const randomPlayers: Player[] = [];
  for (let idx = 0; idx < numPlayers; idx++) {
    const randomIdx = Math.floor(Math.random() * playersCopy.length);
    randomPlayers.push(playersCopy[randomIdx]);
    playersCopy.splice(randomIdx, 1);
  }
  return randomPlayers;
}

const constructPrompt = (players: Player[], prompt: string): string => {
  let finalPrompt = prompt;
  for (let player of players) {
    finalPrompt = finalPrompt.replace(replaceString, player.name)
  }
  return finalPrompt;
}

const gameStateContainsCard = (gameState: GAME_STATE) => {
  return gameState === GAME_STATE.IN_PROGRESS || gameState === GAME_STATE.DONE;
}

function OnScreenCard(props: any) {
  const variant = props.header ? "h6" : "h5";
  const smallLineHeight = props.header ? "card-small-line" : ""
  const iconClassName = props.iconClassName || "";
  const animationStyle = props.animationStyle || "bounce";

  const renderAnimatedCardTitle = () => {
    if (!props.animatedCardTitle) {
      return null;
    }

    const marginTop = !props.header ? "32px" : "16px";
    // const weight = props.header ? "h3" : "h2";
    const weight = props.variant || "h3";

    return (
      <Typography sx={{margin: "16px"}} variant={weight} gutterBottom m={4} className={`funfont-text card-${animationStyle}-text`}>
        {props.animatedCardTitle && props.animatedCardTitle.split('').map((letter: any, idx: number) => {
          const style = { "--i": idx + 1 } as React.CSSProperties;

          return (
            <span key={`${letter}-${idx}`} style={style}>{letter}</span>
          );
        })}
      </Typography>
    )
  }

  const renderHeader = () => {
    if (!props.header) {
      return null;
    }

    return (
      <Typography sx={{margin: '0px 16px 8px 16px'}} variant="body2" gutterBottom m={4} className="card-text">
        {props.header}
      </Typography>
    );
  }

  const renderHint = () => {
    if (!props.hint) {
      return null;
    }
    return (
      <Typography sx={{margin: '0px', marginBottom: '10px', textAlign: 'center', width: '100%', opacity: 0.6}} variant="subtitle2" gutterBottom m={4} className="card-text">
        {props.hint}
      </Typography>
    );
  }

  const getTextMargins = () => {
    if (props.header) {
      return {margin: '16px 32px'};
    } else if (props.animatedCardTitle) {
      return {margin: '16px 32px 16px 32px'}
    }
    return {margin: 'auto 32px'}
  }

  return (
    <Paper id={props.id} elevation={4} className={props.className}>
      <div className={props.hint ? "full-screen-center-div" : "center-text-div"}>
        <IconButton className={`cancel-icon-button ${iconClassName}`} onClick={props.onClick}>
          <CloseIcon />
        </IconButton>
        {renderAnimatedCardTitle()}
        {renderHeader()}
        <Typography sx={getTextMargins()} variant={variant} gutterBottom m={4} className={`card-text card-main-text ${smallLineHeight}`}>
          {props.text}
        </Typography>
        {renderHint()}
      </div>
    </Paper>
  )
}

function BasicCard(props: any) {
  let canReclick = true;
  const promptInfo = props.prompts[props.cardIndex];
  const nextPromptInfo = props.prompts[props.cardIndex + 1];
  const prompt = props.constructedPrompts[props.cardIndex];
  const nextPrompt = props.constructedPrompts[props.cardIndex + 1];
  const [showNextCard, setShowNextCard] = React.useState(true);
  const [cancelOpen, setCancelOpen] = React.useState(false);
  const [flipped, setFlippedCard] = React.useState(false);

  const nextCardSetting = () => {
    if (!canReclick) {
      return;
    }
    if (promptInfo && promptInfo.answer && !flipped) {
      canReclick = false;
      const flippedCard = (window as any).document.getElementById("flipped-card");
      const hiddenCard = (window as any).document.getElementById("hidden-card");
      const topCard = (window as any).document.getElementById('top-card');
      // set hidden card to show, hide the other card, then switch them back after animation
      hiddenCard.style.opacity = '1';
      flippedCard.style.opacity = '1';
      topCard.style.opacity = '0';
      setFlippedCard(true);
      setTimeout(() => {
        canReclick = true;
        hiddenCard.style.opacity = '0';
        topCard.style.opacity = '1';
      }, (animationLength) * millisToSec);
      return;
    }
    if (props.cardIndex + 1 >= props.prompts.length) {
      props.finishGame && props.finishGame();
      return;
    }
    canReclick = false;
    let cardToMoveId = "hidden-card";
    let otherCardId = "flipped-card";
    if (flipped) {
      otherCardId = "hidden-card";
      cardToMoveId = "flipped-card";
    }
    const cardToMove = (window as any).document.getElementById(cardToMoveId);
    const otherCard = (window as any).document.getElementById(otherCardId);
    const topCard = (window as any).document.getElementById('top-card');
    // if (topCard.classList.contains('basic-card-enter')) {
    //   topCard.classList.remove('basic-card-enter');
    // }
    // topCard.classList.add('basic-card-enter');
    cardToMove.style.opacity = '1';
    topCard.style.transform = "scale(90%)";
    setShowNextCard(true);
    const animationName = flipped ? "flippedCardExitAnimation" : "cardExitAnimation";
    topCard.style.animation = `cardEnterAnimation ${animationLength}s ease-in-out forwards`;
    cardToMove.style.animation = `${animationName} ${animationLength}s ease-in-out forwards`;
    setTimeout(() => {
      props.getNextCard && props.getNextCard();
      cardToMove.style.animation = null;
      setFlippedCard(false);
      setShowNextCard(false);
      topCard.style.transform = "scale(100%)";
      cardToMove.style.opacity = '0';
      topCard.style.animation = null;
      canReclick = true;
    }, (animationLength) * millisToSec);
  }

  const setCancelDialog = (value: boolean) => (ev: any) => {
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();
    ev.preventDefault();
    setCancelOpen(value);
  }

  const renderCardBack = () => {
    if (promptInfo && promptInfo.answer) {
      return (
        <OnScreenCard
          id="flipped-card"
          className={`flipped-card prompt-info prompt-answer-color`}
          onClick={setCancelDialog(true)}
          text={promptInfo.answer}
          iconClassName="light-button"
          animatedCardTitle="Answer"
        />
      );
    }
  }

  const getCardTitleFromPrompt = (info: any) => {
    if (!info) {
      return null;
    }
    if (info.type === "khel") {
      return info.type;
    } else if (info.type === "song") {
      return info.type;
    } else if (info.type === "film") {
      return info.type;
    } else if (info.type === "muqabla") {
      return info.type;
    }
    return null;
  }

  const getCardColorFromPrompt = (info: any) => {
    if (!info) {
      return null;
    }
    if (info.type === "khel") {
      return "prompt-khel-color";
    } else if (info.type === "song") {
      return "prompt-song-color";
    } else if (info.type === "film") {
      return "prompt-film-color";
    } else if (info.type === "muqabla") {
      return "prompt-muqabla-color";
    }
    return "prompt-main-color";
  }

  const getAnimationStyle = (info: any) => {
    if (!info) {
      return null;
    }
    if (info.type === "khel") {
      return "bounce";
    } else if (info.type === "song") {
      return "wave";
    } else if (info.type === "film") {
      return "slide";
    } else if (info.type === "muqabla") {
      return "out-in";
    }
    return null;
  }

  const topCardTitle = getCardTitleFromPrompt(promptInfo);
  const nextCardTitle = getCardTitleFromPrompt(nextPromptInfo);
  const ct = showNextCard ? nextCardTitle : topCardTitle;
  const topCardColor = getCardColorFromPrompt(promptInfo);
  const nextCardColor = getCardColorFromPrompt(nextPromptInfo);
  const flippedClass = flipped && "basic-card-inner-flipped" || "";

  return (
    <Paper onClick={nextCardSetting} className="basic-card" elevation={10}>
        <OnScreenCard
          id="top-card"
          className={`top-card prompt-info ${showNextCard ? nextCardColor : topCardColor}`}
          onClick={setCancelDialog(true)}
          text={showNextCard && nextPrompt || prompt}
          iconClassName="light-button"
          animatedCardTitle={ct}
          animationStyle={showNextCard ? getAnimationStyle(nextPromptInfo) : getAnimationStyle(promptInfo)}
          // variant={showNextCard ? !!nextPromptInfo.header ? "h6" : null : promptInfo.header ? "h6" : null}
          header={showNextCard ? nextPromptInfo.header : promptInfo.header}
          hint={showNextCard ? nextPromptInfo.hint : promptInfo.hint}
        />
        <div className={`basic-card-inner ${flippedClass}`}>
          <OnScreenCard
            id="hidden-card"
            className={`hidden-card prompt-info ${topCardColor}`}
            onClick={setCancelDialog(true)}
            text={prompt}
            iconClassName="light-button"
            animatedCardTitle={topCardTitle}
            animationStyle={getAnimationStyle(promptInfo)}
            header={promptInfo.header}
            hint={promptInfo.hint}
          />
          {renderCardBack()}
        </div>
      <CancelCard open={cancelOpen} onClick={setCancelDialog(false)} onConfirm={props.onCloseGame} />
    </Paper>
  );
}

function PlayerInput(props: any) {
  const players = props.players;

  const onKeyPress = (ev: any) => {
    if (ev.key === 'Enter' && ev.target.value && ev.target.value.length > 0) {
      // Do code here
      ev.preventDefault();
      props.addPlayer(ev.target.value, () => {
        ev.target.value = '';
      });
    }
  }

  const onAddButtonPress = (ev: any) => {
    ev.preventDefault();
    const playerName = (window as any).document.getElementById('player-name-input').value;
    if (playerName && playerName.length > 0) {
      props.addPlayer(playerName, () => {
        (window as any).document.getElementById('player-name-input').value = '';
      });
    }
  }

  const renderPlayerChip = (player: Player) => {
    return (
      <div className="player-chip" key={player.id}>
        <Chip
          sx={{
            boxShadow: 4,
            borderRadius: 1,
            marginLeft: '5px',
            marginBottom: '8px',
            fontFamily: 'FunFont',
            color: 'floralwhite',
            opacity: '0.9'
          }}
          key={player.id}
          label={player.name}
          onDelete={() => props.removePlayer(player)}
        />
      </div>
    )
  }

  return (
    <div className="player-input">
      <div className="player-input-title">
        <Typography variant="h1" gutterBottom className="funfont-text">
          OUT DESI
        </Typography>
      </div>
      <div className="player-input-input">
        <TextField
          className="funfont-text"
          fullWidth
          placeholder="Player Name"
          sx={{
            '& .MuiOutlinedInput-root': {
              background: 'floralwhite',
              fontFamily: 'FunFont',
              paddingRight: '10px'
            },
            'marginBottom': '30px'
          }}
          onKeyPress={onKeyPress}
          InputProps={{
            id: 'player-name-input',
            endAdornment: 
              <InputAdornment position="end">
                <Paper
                  onMouseDown={onAddButtonPress}
                  elevation={3}
                  sx={{backgroundColor: '#d98b98', borderRadius: '10%', color: 'floralwhite', marginRight: '1px'}}
                >
                  <IconButton 
                    sx={{color: 'floralwhite'}}
                  >
                    <AddIcon />
                  </IconButton>
                </Paper>
              </InputAdornment>
          }}
        />
        <Paper elevation={3}>
          <Button
            className="funfont-text"
            fullWidth
            variant="contained"
            onClick={props.onStartGame}
            sx={{backgroundColor: '#fca444'}}
          >
            Done!
          </Button>
        </Paper>
      </div>
      <div className="player-input-list-players">
        {players && players.map((pl: Player) => renderPlayerChip(pl))}
      </div>
    </div>
  )
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CancelCard(props: any) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClick}
      TransitionComponent={Transition}
      PaperProps={{
        className:"cancel-card-dialog"
      }}>
      <DialogTitle className="funfont-text" sx={{textAlign: 'center'}}>
        {"Kabhi Alvida Naa Kehna 😭"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: 'floralwhite' }} className="funfont-text">
          Are you sure you want to stop playing?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClick} sx={{flexGrow: 1, backgroundColor: '#7d8588'}} variant="contained" className="funfont-text">No</Button>
        <Button onClick={props.onConfirm} sx={{flexGrow: 1, backgroundColor: '#e2403b'}} variant="contained" className="funfont-text">Yes</Button>
      </DialogActions>
    </Dialog>
  );
}

function DoneCard(props: any) {
  return (
    <OnScreenCard
      id="done-card"
      className="done-card"
      onClick={props.onCloseGame}
      animatedCardTitle="GAME OVER"
      animationStyle="none"
      variant="h2"
    />
  );
}

function App() {
  const [gameState, setGameState] = React.useState(GAME_STATE.INIT);
  const [players, onPlayerChange] = React.useState<Player[]>([]);
  const [validPrompts, setValidPrompts] = React.useState<any[]>([]);
  const [cardIndex, setCardIndex] = React.useState(0);
  const [constructedPrompts, setConstructedPrompts] = React.useState<string[]>([]);

  const startGame = () => {
    const newPrompts = prompts.filter(prompt => !prompt.minNumberOfPlayers || players.length >= prompt.minNumberOfPlayers);
    const newValidPrompts = shuffle(newPrompts).slice(0, getRandomInt(40, 60));
    setValidPrompts(newValidPrompts);
    addNewRealPrompts(5, newValidPrompts);
    setGameState(GAME_STATE.IN_PROGRESS);
  }

  const addPlayer = (name: string, onSuccess?: any) => {
    const newPlayer = createNewPlayer(name);
    onPlayerChange(players.concat([newPlayer]));
    onSuccess && onSuccess();
  }

  const removePlayer = (player: Player) => {
    if (!player) {
      return;
    }
    onPlayerChange(players.filter(pl => pl.id != player.id));
  }

  const addNewRealPrompts = (totalNewPrompts: number, overridePrompts?: any[]) => {
    const promptsToUse = overridePrompts || validPrompts;
    const newPromptWords: string[] = [];
    for (let numPrompt = 0; numPrompt < totalNewPrompts; numPrompt++) {
      const index = constructedPrompts.length + numPrompt;
      if (index >= promptsToUse.length) {
        break;
      }
      const nextPrompt = promptsToUse[index];
      newPromptWords.push(constructPrompt(getRandomPlayers(nextPrompt.minNumberOfPlayers, players), nextPrompt.prompt));
    }
    setConstructedPrompts(constructedPrompts.concat(newPromptWords));
  }

  const getNextCard = () => {
    addNewRealPrompts(1);
    setCardIndex(cardIndex + 1);
  }

  const finishGame = () => {
    setGameState(GAME_STATE.DONE);
  }

  const closeGame = (ev: any) => {
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();
    ev.preventDefault();
    onPlayerChange([]);
    setValidPrompts([]);
    setCardIndex(0);
    setConstructedPrompts([]);
    setGameState(GAME_STATE.INIT);
  }

  const renderGameCard = () => {
    if (gameState === GAME_STATE.INIT) {
      return (
        <PlayerInput
          addPlayer={addPlayer}
          removePlayer={removePlayer}
          players={players}
          onStartGame={startGame}
        />
      );
    } else if (gameState === GAME_STATE.IN_PROGRESS) {
      return (
        <BasicCard
          players={players}
          prompts={validPrompts}
          cardIndex={cardIndex}
          getNextCard={getNextCard}
          constructedPrompts={constructedPrompts}
          finishGame={finishGame}
          onCloseGame={closeGame}
        />
      );
    } else if (gameState === GAME_STATE.DONE) {
      return (
        <DoneCard
          onCloseGame={closeGame}
        />
      );
    }
  }

  const gameStateClass = !gameStateContainsCard(gameState) ? "App-in-progress-background" : "";
  return (
    <div className="App">
      <div className={`App-body ${gameStateClass}`}>
        { renderGameCard() }
      </div>
    </div>
  );
}

export default App;
